import type { FC } from 'react';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/standard-button';
import CommentIcon from '@atlaskit/icon/core/comment';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import { fg } from '@confluence/feature-gating';
import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import {
	GeneralShortcutListener,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR,
	ShortcutVisualizer,
} from '@confluence/shortcuts';
import {
	UnreadCommentCount,
	useUnreadComments,
	UnreadTooltipText,
} from '@confluence/unread-comments';

import { getUnreadInlineCommentsListState } from './CommentButtonUtils';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minWidth: '30px',
		paddingLeft: token('space.025'),
		paddingRight: token('space.025'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentCountWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

type CommentButtonPlaceholderProps = {
	isDisabled?: boolean;
	handleCommentButtonClick?: (evt: any) => void;
	style?: { padding: string };
	onRender?: () => void;
	contentId?: string;
};

export const CommentButtonPlaceholder: FC<CommentButtonPlaceholderProps> = ({
	isDisabled = true,
	handleCommentButtonClick = () => {},
	style,
	onRender,
	contentId,
}) => {
	useEffect(() => {
		if (!isDisabled) {
			onRender && onRender();
		}
	}, [isDisabled, onRender]);
	const { formatMessage } = useIntl();

	const [{ unreadCommentsListState, allComments }] = useUnreadComments();
	const unreadInlineComments = getUnreadInlineCommentsListState(
		unreadCommentsListState,
		allComments,
	);
	const unreadInlineCommentsListState = fg('fix_legacy_show_comments_button')
		? unreadInlineComments
		: unreadCommentsListState;
	const eventEmitter: AnnotationUpdateEmitter = getRendererAnnotationEventEmitter();

	if (isDisabled) {
		return (
			<StyledButton
				testId="comment-button-placeholder"
				data-test-id="comment-button-placeholder"
				appearance="subtle"
				iconBefore={<CommentIcon label="" spacing="spacious" />}
				isDisabled
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={style}
			/>
		);
	} else {
		return (
			<>
				<GeneralShortcutListener
					accelerator={[
						TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER,
						TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR,
					]}
					listener={(e) => {
						if (document.getElementById('renderer-comments-sidebar')) {
							eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
							eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
						} else {
							handleCommentButtonClick(e);
						}
					}}
				/>
				<Tooltip
					content={
						unreadInlineCommentsListState?.length > 0 ? (
							<UnreadTooltipText count={unreadInlineCommentsListState?.length} />
						) : (
							<ShortcutVisualizer
								shortcut={TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR}
								contentBefore={
									<FormattedMessage
										id="comment-button.tooltip"
										defaultMessage="Show comments"
										description="Tooltip for button that shows inline comments for a page"
									/>
								}
							/>
						)
					}
					hideTooltipOnClick
					position="bottom"
				>
					<CommentCountWrapper onClick={handleCommentButtonClick}>
						<StyledButton
							data-id="comment-button-placeholder"
							data-cy="comment-button-placeholder"
							testId="comment-button-placeholder"
							appearance="subtle"
							iconBefore={
								<>
									<CommentIcon
										label={
											unreadInlineCommentsListState?.length > 0
												? formatMessage({
														id: 'comment-button.unread.comments.icon.label',
														description:
															'aria-label for the comment button to display unread inline comments',
														defaultMessage: 'You have unread comments',
													})
												: formatMessage({
														id: 'comment-button.icon.label',
														description:
															'aria-label for the comment button to display the first inline comment',
														defaultMessage: 'Show comments',
													})
										}
										spacing="spacious"
									/>
									{unreadInlineCommentsListState?.length > 0 && (
										<UnreadCommentCount pageId={contentId!} pageMode="view" />
									)}
								</>
							}
							isDisabled={isDisabled}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={style}
						/>
					</CommentCountWrapper>
				</Tooltip>
			</>
		);
	}
};
