import React from 'react';

import { LoadablePaint, LoadableAfterPaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { fg } from '@confluence/feature-gating';
import {
	ExperienceStart,
	SHARE_AND_RESTRICT_BUTTON_LOAD_EXPERIENCE,
} from '@confluence/experience-tracker';

import { ShareRestrictAndCopyButtonPlaceholder } from './v2/shareAndRestrictButton/ShareRestrictAndCopyButtonPlaceholder';
import { SHARE_BUTTON_PAGE_SEGMENT_LOAD } from './perf.config';

export { getClosestAncestorWithViewRestrictions } from './v2/controller/queries/dataToQueriesState';
import type { ShareRestrictAndCopyCoordinatorProps } from './v2/shareAndRestrictButton/ShareRestrictAndCopyCoordinator';
export {
	type ContentType,
	type ContentSubType,
} from './v2/shareAndRestrictButton/ShareRestrictAndCopyCoordinator';

const ShareAndRestrictButtonSSRLoader = LoadablePaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ShareRestrictAndCopyButton" */ './v2/shareAndRestrictButton/ShareRestrictAndCopyCoordinator'
			)
		).ShareRestrictAndCopyCoordinator,
});

const ShareAndRestrictButtonLoader = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ShareRestrictAndCopyButton" */ './v2/shareAndRestrictButton/ShareRestrictAndCopyCoordinator'
			)
		).ShareRestrictAndCopyCoordinator,
	loading: ShareRestrictAndCopyButtonPlaceholder,
});

export type ShareAndRestrictButtonProps = ShareRestrictAndCopyCoordinatorProps;

export const ShareAndRestrictButton = (props: Omit<ShareAndRestrictButtonProps, 'flags'>) => (
	<>
		<PageSegmentLoadStart
			metric={SHARE_BUTTON_PAGE_SEGMENT_LOAD}
			isCustomStart
			key={props.contentId}
		/>
		<ExperienceStart name={SHARE_AND_RESTRICT_BUTTON_LOAD_EXPERIENCE} />
		{!fg('confluence_frontend_object_header') ? (
			<ShareAndRestrictButtonLoader {...props} />
		) : fg('confluence_frontend_usd_ssr') ? (
			<ShareAndRestrictButtonSSRLoader {...props} />
		) : (
			<ShareAndRestrictButtonLoader {...props} />
		)}
	</>
);
