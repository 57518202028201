import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/custom-theme-button';
import UnstarredIcon from '@atlaskit/icon/core/star-unstarred';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minWidth: '30px',
		paddingLeft: token('space.025'),
		paddingRight: token('space.025'),
	},
});

export const FavouriteButtonPlaceholder = () => (
	<StyledButton
		data-test-id="page-favourite-button-placeholder"
		appearance="subtle"
		iconBefore={<UnstarredIcon label="" color="currentColor" spacing="spacious" />}
		isDisabled
	/>
);
