import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import WatchIcon from '@atlaskit/icon/core/eye-open';
import Button from '@atlaskit/button/standard-button';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minWidth: '30px',
		paddingLeft: token('space.025'),
		paddingRight: token('space.025'),
	},
});

export const WatchButtonPlaceholder = ({ compact }) => (
	<StyledButton
		testId="watch-button-placeholder"
		data-test-id="watch-button-placeholder"
		appearance="subtle"
		iconBefore={<WatchIcon label="WatchIcon" spacing="spacious" />}
		isDisabled
		spacing={compact ? 'compact' : 'default'}
	/>
);

WatchButtonPlaceholder.propTypes = {
	compact: PropTypes.bool,
};
