import React from 'react';

import { SplitButton, IconButton } from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { ShareAndRestrictButtonPlaceholder } from './ShareAndRestrictButtonPlaceholder';

const iconSkeletonStyle = xcss({
	width: '16px',
	height: '16px',
	borderRadius: '2px',
	background: token('color.background.accent.gray.subtlest.hovered'),
});

const SkeletonGlyph = () => <Box testId="icon-skeleton" xcss={iconSkeletonStyle} />;

export const ShareRestrictAndCopyButtonPlaceholder = () => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<div {...ssrPlaceholderIdProp}>
			<SplitButton>
				<ShareAndRestrictButtonPlaceholder />
				<IconButton
					testId="copy-link-button-placeholder"
					icon={SkeletonGlyph}
					label=""
					isDisabled
				/>
			</SplitButton>
		</div>
	);
};
